<template>
  <div>
    <h1 class="apply-theme-color bottom-padding-for-title">{{$t("hierarchyManagement")}}</h1>
    <div class="hm-wrapper" >
      <div class="hm-tree background-white">
        <TreeView  v-on:hierarchy-selected="handleHierarchySelection"/>
      </div>
      <div class="hm-details background-white">
        <Tabs ref="tabs" :key="reloadTabs" :tabs="tabs" colorTheme="light" @addhierarchy="addHierarchy"></Tabs>
      </div>
      <div v-if="addNewHierarchyClicked">
        <AddNewHierarchy  :parentHierachyId="addNewHierarchyParentId" :parentHierarchyLevel="parentHierarchyLevel" @addhierarchyclosed="addHierarchyClosed"></AddNewHierarchy>      </div>
    </div>
  </div>
</template>
<script>
import TreeView from '@/app/Pages/dashboard/HierarchyManagement/components/TreeView.vue'
import Tabs from '@/app/shared/components/Tabs.vue'
import { mapActions, mapState } from 'vuex'
import AddNewHierarchy from '@/app/Pages/dashboard/HierarchyManagement/components/AddNewHierarchy.vue'
import { defaultToast, ToastType } from '@/utils/toastUtils'

export default {
  name: 'HierarchyManagement',
  metaInfo: {
    title: 'Facility Management'
  },
  components: {
    TreeView,
    Tabs,
    AddNewHierarchy
  },
  async mounted () {
    this.toggleLoader()
    await this.getAllHierarchies()
    this.toggleLoader()
  },
  computed: {
    ...mapState('HiearachyManagement', [
      'allHierarchyResponse',
      'hierarchyBasicDetails',
      'selectedHierarchyNode',
      'showLoader',
      'addNewHierarchConfig'
    ])
  },
  methods: {
    ...mapActions('HiearachyManagement', ['getAllHierarchies', 'getHierarchyDetails', 'getParentMonitoringMethods', 'selectNode', 'toggleLoader', 'getAddNewHierarchyConfig']),
    async handleHierarchySelection (hierarchyNode) {
      this.toggleLoader()
      if (hierarchyNode !== null) {
        const facilityId = hierarchyNode.id
        await this.selectNode({ node: hierarchyNode })
        await this.getHierarchyDetails({ hierarchyId: facilityId })
        await this.getParentMonitoringMethods({ params: { hierarchyId: this.hierarchyBasicDetails.parentId } })
        if (this.hierarchyBasicDetails !== null) {
          this.tabs[0].to_link = '/dashboard/hierarchymanagement/hierarchy_details/' + facilityId
          this.tabs[1].to_link = '/dashboard/hierarchymanagement/logins/' + facilityId
          this.tabs[2].to_link = '/dashboard/hierarchymanagement/communication/' + facilityId
          this.reloadTabs = facilityId + ''
        }
      }
      if (this.$refs.tabs.active_tab === -1) {
        this.$router.push(this.tabs[0].to_link)
        this.$refs.tabs.changeTab(0)
      }
      this.toggleLoader()
    },
    async addHierarchy (parentInfo) {
      this.addNewHierarchyParentId = parentInfo.id
      this.parentHierarchyLevel = parentInfo.fetchAtParentLevel ? this.hierarchyBasicDetails.level : this.hierarchyBasicDetails.level + 1 // Setting the parent hierarchy level for the new hierarchy based on whether it is being added below or at the same level.
      await this.getAddNewHierarchyConfig({ params: { hierarchyId: parentInfo.id, selectedHierarchyId: this.hierarchyBasicDetails.id, fetchAtParentLevel: parentInfo.fetchAtParentLevel } })
      if (this.addNewHierarchConfig.responseForType.length > 0) {
        this.addNewHierarchyClicked = true
      } else {
        defaultToast(ToastType.Error, this.$t('cannot_create_hierarchy_at_the_level'))
      }
    },
    addHierarchyClosed () {
      this.addNewHierarchyClicked = false
    }
  },
  watch: {
  },
  data () {
    return {
      tabs: [
        {
          tab_title: 'hierarchy_details',
          tab_content: null,
          to_link: '/dashboard/hierarchymanagement/hierarchy_details/-1',
          alternate_title: 'hierarchy_details'
        },
        {
          tab_title: 'logins',
          tab_content: null,
          to_link: '/dashboard/hierarchymanagement/logins/-1',
          alternate_title: 'logins'
        },
        {
          tab_title: 'communication',
          tab_content: null,
          to_link: '/dashboard/hierarchymanagement/communication/-1',
          alternate_title: 'communication'
        }
      ],
      addNewHierarchyClicked: false,
      addNewHierarchyParentId: null,
      reloadTabs: '',
      parentHierarchyLevel: null
    }
  }
}
</script>
<style lang="scss" scoped>
.hm-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: var(--background-bg-color);
  height: 100% !important;
}
.hm-tree {
  flex: 1 0 35%;
  max-width: 40%;
}
.hm-details {
  margin-left:10px;
  flex: 1 0 50%;
}
.background-white {
  // background: var(--background-color-primary);
  background: var(--background-color-primary-light-dark);
}
.toast-error {
  background-color: rgb(218, 53, 53) !important;
  font-size: 14px !important;
}
.apply-theme-color {
  color:var(--text-primary-color);

}
.bottom-padding-for-title {
  padding-bottom: 2%;
}
</style>
