<template>
<div>
  <div class="search-container">
    <div class="display-inlined search-bar-width">
    <Input v-model="search" @change="handleSearch"></Input>
    </div>
    <div class="display-inlined place-trash-icon "><font-awesome-icon icon="search"  color="var(--text-primary-color)"/></div>
  </div>
<v-treeview ref="tree"  return-object :open.sync="open" :items="dataForTree" :item-key="'id'" :selection-type="'independent'" :activatable=true @update:active="handleActiveNodeWhenClicked" :search="search" :name="'tree-view-component'" :dark="darkModeState" :transition=true>
  <template v-slot:label="{item}">
     <div class="tree-text">{{item.name}}</div>
  </template>
</v-treeview>
</div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Input from '@/app/shared/components/Input.vue'
export default {
  name: 'TreeView',
  components: {
    Input
  },
  props: {
  },
  async mounted () {
    this.updateTreeRef(this.$refs.tree)
  },
  computed: {
    ...mapState('HiearachyManagement', ['allHierarchyResponse']),
    ...mapGetters(['darkModeState', 'accessibilityModeState'])
  },
  data () {
    return {
      dataAsMap: {},
      dataForTree: this.transformDataFromAPIToRequiredTreeStructure(this.allHierarchyResponse),
      search: null,
      open: [],
      lastOpen: [],
      allOpened: false,
      searchThemeLight: true
    }
  },
  watch: {
    allHierarchyResponse () {
      this.dataForTree = this.transformDataFromAPIToRequiredTreeStructure(this.allHierarchyResponse)
      this.updateTreeRef(this.$refs.tree)
    }
  },
  methods: {
    ...mapActions('HiearachyManagement', ['updateTreeRef']),
    transformDataFromAPIToRequiredTreeStructure (treeData) {
      if (treeData === undefined) {
        return []
      }
      const finalData = []
      const dataAsMap = {}
      treeData.forEach((item, _) => {
        dataAsMap[item.id] = item
      })
      treeData.forEach((item, _) => {
        if (item.parentId in dataAsMap) {
          if (dataAsMap[item.parentId].children === undefined) {
            dataAsMap[item.parentId].children = [item]
          } else {
            dataAsMap[item.parentId].children.push(item)
          }
        } else {
          finalData.push(item)
        }
      })
      treeData.forEach((item, _) => {
        item.name = item.name + (item.children !== undefined ? ' (' + item.children.length + ')' : '')
      })
      this.dataAsMap = dataAsMap
      return finalData
    },
    handleActiveNodeWhenClicked (item) {
      if (item !== undefined && Array.isArray(item) && item.length === 1) {
        this.$emit('hierarchy-selected', item[0])
      }
    },
    handleSearch (val) {
      if (val === '') {
        if (this.allOpened) {
          this.$refs.tree.updateAll(false)
          this.allOpened = false
          this.open = this.lastOpen
        }
        return
      }
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open
          this.allOpened = true
          this.$refs.tree.updateAll(true)
        }
      } else {
        this.$refs.tree.updateAll(false)
        this.allOpened = false
        this.open = this.lastOpen
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-container {
  margin-left: 5%;
  margin-top: 4%;
  display: flex;
}
.display-inlined {
  display: inline;
}
.place-trash-icon {
  margin-top: 3%;
  position: relative;
  right: 5%;
}
.search-bar-width {
  width: 90%;
}
.background-color-apply {
  background: var(--background-color-primary) !important;
}
.tree-text {
  font-size: 14px;
  line-height: 14px;
  color: var(--tabs-text-color);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.05em;
  width: 90%;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
