<template>
    <div>
    <Modal :headingText="$t(`create_hierarchy`)"  @close="close()" @click="onOk()" :disableConfirmBtn="!overallError">
      <div slot="body" v-if="Object.keys(addNewHierarchConfig).length">
        <div name = "add-hierarchy-body" >
            <div class="adjust-width">
            <Input :label="$t(`parent_hierarchy_id`)" orientation="v" name="Parent Hierarchy Id" :value="parentHierachyId" :isDisabled="true"/>
            <Input :label="$t(`level`)"  orientation="v" name="Parent Hierarchy Level" :value="(parentHierarchyLevel).toString()" :isDisabled="true"/>
            </div>
            <Select :label="$t(`type`)"  :allOptions="availableTypes" name="type" orientation="v" v-model="type" :hasError="typeError" :errorMessage="$t(typeErrorMessage)"/>
            <div class="adjust-width">
            <Input :label="$t(`name`)"  orientation="v" name="Name" v-model="name" :hasError="nameError" :errorMessage="$t(nameErrorMessage)"/>
            <Input :label="$t(`code`)"  orientation="v" name="Code" v-model="code" :hasError="codeError" :errorMessage="$t(codeErrorMessage)"/>
            </div>
            <Checkbox v-if="availableMonitoringMethodsAsOptions.length > 0" :label="''"  :optionsWithKeyValue="availableMonitoringMethodsAsOptions" v-model="monitoringMethods" orientation="v" />
            <br>
        </div>
      </div>
    </Modal>
    </div>
</template>
<script>
import Input from '@/app/shared/components/Input.vue'
import Modal from '@/app/shared/components/Modal.vue'
import Checkbox from '@/app/shared/components/CheckboxGroup.vue'
import Select from '@/app/shared/components/Select.vue'

import { mapActions, mapState } from 'vuex'
export default {
  name: 'addHierarchy',
  components: {
    Input,
    Modal,
    Checkbox,
    Select
  },
  props: {
    parentHierachyId: {
      default: null
    },
    parentHierarchyLevel: {
      default: null
    }
  },
  computed: {
    ...mapState('HiearachyManagement', [
      'allHierarchyResponse',
      'addNewHierarchConfig'
    ]),
    availableTypes () {
      return this.addNewHierarchConfig.responseForType.map(elm => { return { Key: elm, Value: elm } })
    },
    availableMonitoringMethodsAsOptions () {
      const that = this
      const response = this.addNewHierarchConfig.responseForParentMonitoringMethods.filter((key) => this.allowStringMap[key])
        .reduce((cur, key) => { return [...cur, { Key: key, Value: that.allowStringMap[key] }] }, [])
      return response
    },
    overallError () {
      return !this.codeError && !this.nameError && !this.typeError && !this.disableSaveButton
    }
  },
  methods: {
    ...mapActions('HiearachyManagement', [
      'getAddNewHierarchyConfig',
      'addNewHierarchy'
    ]),
    getParentDetails (parentId) {
      return this.allHierarchyResponse.filter(elm => elm.id === parentId)[0]
    },
    close () {
      this.$emit('addhierarchyclosed')
    },
    async onOk () {
      this.disableSaveButton = true
      await this.addNewHierarchy({
        data: {
          hierarchy: {
            name: this.name,
            code: this.code,
            parentId: this.parentHierachyId.toString(),
            type: this.type.Key,
            level: (this.parentHierarchyLevel).toString()
          },
          configs: this.getSelectedMonitoringMethodsAsMap()
        }
      })
      this.disableSaveButton = false

      this.$emit('addhierarchyclosed')
    },
    getSelectedMonitoringMethodsAsMap () {
      const that = this
      return Object.keys(this.allowStringMap).reduce((cur, key) => { return Object.assign(cur, { [this.configNameForMonitoringMethod[key]]: that.monitoringMethods.includes(key) }) }, {})
    }
  },
  data () {
    return {
      parentDetails: {},
      type: [],
      name: null,
      code: null,
      monitoringMethods: [],
      allowStringMap: {
        Merm: 'allow_MERM_registration',
        VOT: 'allow_VOT',
        NNDots: 'allow_99dots',
        NNDotsLite: 'allow_99dotsLite',
        None: 'allow_follow_up_without_tech'
      },
      configNameForMonitoringMethod: {
        Merm: 'HasMerm',
        VOT: 'HasVot',
        NNDots: 'Has99Dots',
        NNDotsLite: 'Has99DotsLite',
        None: 'HasNone'
      },
      nameError: true,
      nameErrorMessage: '',
      codeError: true,
      codeErrorMessage: '',
      typeError: true,
      typeErrorMessage: '',
      disableSaveButton: false
    }
  },
  watch: {
    async parentHierachyId () {
      this.parentDetails = this.getParentDetails(this.parentHierachyId)
      await this.getAddNewHierarchyConfig({ params: { hierarchyId: this.parentHierachyId } })
    },
    name () {
      if (this.name?.length === 0) {
        this.nameError = true
        this.nameErrorMessage = 'enter_vaild_name'
      } else if (this.name?.length > 255) {
        this.nameError = true
        this.nameErrorMessage = 'name_to_be_less_than'
      } else {
        this.nameError = false
      }
    },
    code () {
      if (this.code.length === 0) {
        this.codeError = true
        this.codeErrorMessage = 'enter_vaild_code'
      } else if (this.code.length > 10 || this.code !== this.code.toUpperCase()) {
        this.codeError = true
        this.codeErrorMessage = 'hierarchy_code_validation_msg'
      } else {
        this.codeError = false
      }
    },
    type () {
      if (this.type.length === 0) {
        this.typeError = true
        this.typeErrorMessage = 'hierarchy_type_selection_err_msg '
      } else {
        this.typeError = false
      }
    }
  }
}
</script>
<style scoped>
.adjust-width {
    width: calc(100% - 32px);
}
</style>
